<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="font-weight-bolder font-medium-3">
              ●
              <span class="text-primary">[รัฐบาล] หวยไทย</span>
              ,
              <span class="text-danger">เลขท้าย</span>
              <span
                class="text-muted"
              ><small>(เปลี่ยนได้ที่แถบเมนูด้านบน)</small></span>
            </div>
          </div>
          <div class="col-12">
            <div
              id="__BVID__639"
              class="tabs navtab-bg"
            >
              <div class="">
                <ul
                  id="__BVID__639__BV_tab_controls_"
                  role="tablist"
                  class="nav nav-tabs"
                >
                  <li
                    role="presentation"
                    class="nav-item"
                  >
                    <a
                      id="__BVID__640___BV_tab_button__"
                      role="tab"
                      aria-selected="true"
                      aria-setsize="1"
                      aria-posinset="1"
                      href="#"
                      target="_self"
                      class="nav-link active bg-primary text-white"
                      aria-controls="__BVID__640"
                    >เลขท้าย</a>
                  </li>
                </ul>
              </div>
              <div
                id="__BVID__639__BV_tab_container_"
                class="tab-content"
              >
                <div
                  id="__BVID__640"
                  role="tabpanel"
                  aria-hidden="false"
                  class="tab-pane active"
                  aria-labelledby="__BVID__640___BV_tab_button__"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                          class="table table-bordered table-sm table-hover"
                        >
                          <thead class="thead-dark">
                            <tr>
                              <th
                                class="text-center align-middle"
                                style="width: 10rem"
                              />
                              <th class="text-center">
                                3 ตัวบน
                              </th>
                              <th class="text-center">
                                3 ตัวล่าง
                              </th>
                              <th class="text-center">
                                3 ตัวโต๊ด
                              </th>
                              <th class="text-center">
                                2 ตัวบน
                              </th>
                              <th class="text-center">
                                2 ตัวล่าง
                              </th>
                              <th class="text-center">
                                วิ่งบน
                              </th>
                              <th class="text-center">
                                วิ่งล่าง
                              </th>
                              <th style="width: 7rem" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center">
                                <div class="my-3 text-primary">
                                  <b>0 = ไม่รับ</b>
                                </div>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  0
                                </p>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  0
                                </p>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  100
                                </p>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  1,000
                                </p>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  1,000
                                </p>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  0
                                </p>
                              </td>
                              <td class="text-center">
                                <p class="my-3">
                                  0
                                </p>
                              </td>
                              <td class="text-center">
                                <button
                                  type="button"
                                  class="btn my-3 btn-primary btn-sm"
                                  @click="showModal"
                                >
                                  แก้ไข
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12 mt-1">
                      <span
                        class="settime mb-2"
                        @click="isShow"
                      >
                        <span>ตั้งเวลาปิดรับของ [รัฐบาล] หวยไทย</span>
                        <i
                          data-v-07452373=""
                          data-name="chevron-down"
                          data-tags=""
                          data-type="chevron-down"
                          class="
                            icon-xs
                            ml-2
                            align-middle
                            feather feather--chevron-down
                          "
                        ><svg
                          data-v-07452373=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="
                              feather feather-chevron-down feather__content
                            "
                        >
                          <polyline points="6 9 12 15 18 9" /></svg></i>
                      </span>
                      <div v-if="showoff">
                        <div class="card-text mb-3 mt-2">
                          <div class="col-6 card-group">
                            <div class="card custom-border-dark">
                              <header class="p-1 bg-dark text-white">
                                ตั้งเวลา ปิด รับของ
                              </header>
                              <div class="card-body">
                                <p class="card-text" />

                                <div class="custom-control custom-checkbox">
                                  <input
                                    id="checkbox-2"
                                    type="checkbox"
                                    name="checkbox-2"
                                    class="custom-control-input"
                                    value="false"
                                  ><label
                                    for="checkbox-2"
                                    class="custom-control-label"
                                  >ตั้งตามบริษัท</label>
                                </div>
                                <dl class="row mt-2">
                                  <dt class="col-sm-4">
                                    ปิดรับ
                                  </dt>
                                  <dd class="col-sm-8">
                                    <date-picker
                                      v-model="StopBetTime"
                                      type="time"
                                      value-type="format"
                                      format="HH:mm"
                                    />
                                  </dd>
                                  <dt class="col-sm-4">
                                    เวลาปิดของคุณ
                                  </dt>
                                  <dd class="col-sm-8 text-primary">
                                    December 30, 2022 at 3:20 PM
                                  </dd>
                                  <dt class="col-sm-4">
                                    เวลาปิดของบริษัท
                                  </dt>
                                  <dd class="col-sm-8">
                                    December 30, 2022 at 3:20 PM
                                  </dd>
                                </dl>
                                <div class="row justify-content-center">
                                  <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                  >
                                    <span>บันทึก</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="font-weight-bolder font-medium-3 mt-2">
                        ตั้งค่าการรับของเป็นรายตัว (เฉพาะงวด)
                        <span class="text-primary">30-12-2022</span>
                        ,
                        <span class="text-danger">เลขท้าย</span>
                        <span
                          class="text-muted"
                        ><small>(เปลี่ยนได้ที่แถบเมนูด้านบน)</small></span>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              3 ตัวบน
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              3 ตัวล่าง
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              3 ตัวโต๊ด
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              2 ตัวบน
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              2 ตัวล่าง
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              วิ่งบน
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div class="card border border-primary">
                            <div class="p-1 bg-primary text-white">
                              วิ่งล่าง
                            </div>
                            <div class="card-body">
                              <div class="list-group list-group-flush">
                                <div class="mb-2">
                                  <div
                                    role="group"
                                    class="
                                      input-group
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="หมายเลข"
                                      class="form-control"
                                    >
                                    <input
                                      type="number"
                                      placeholder="จำนวนเงิน"
                                      class="form-control"
                                    >
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                      >
                                        <span>เพิ่ม</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="border rounded px-2 py-2 bg-light">
                                  <div>
                                    <div class="text-center text-muted">
                                      ไม่มีข้อมูล
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <b-modal
      ref="my-modal"
      title="แก้ไขตั้งค่ารับของ ตามชนิดหวย"
      ok-title="บันทึก"
      cancel-title="ยกเลิก"
    >
      <div class="modal-body">
        <div class="form-row">
          <fieldset class="form-group col-6">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
            >
              กลุ่มหวย
            </legend>
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <input
                type="text"
                readonly="readonly"
                class="form-control is-valid"
              >
              <div class="invalid-feedback">
                กรุณากรอกชื่อ
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group col-6">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
            >
              ชนิดหวย
            </legend>
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <input
                type="text"
                readonly="readonly"
                class="form-control is-valid"
              >
              <div class="invalid-feedback">
                กรุณากรอกชื่อ
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group col-12">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
            >
              การแทง
            </legend>
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <input
                type="text"
                readonly="readonly"
                class="form-control is-valid"
              >
              <div class="invalid-feedback">
                กรุณากรอกชื่อ
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group col-12">
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <div class="table-responsive">
                <table
                  role="table"
                  class="table b-table"
                >
                  <thead
                    role="rowgroup"
                    class="thead-light"
                  >
                    <tr
                      role="row"
                      class=""
                    >
                      <th
                        role="columnheader"
                        scope="col"
                        class="text-center"
                      >
                        การแทง
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        class="text-center"
                      >
                        จำนวนเงินที่ถือสู้
                        <span class="text-danger">ต่อตัว</span>
                        <input
                          id="__BVID__420"
                          type="number"
                          min="0"
                          step="1"
                          class="form-control"
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        3 ตัวบน
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__425"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        3 ตัวล่าง
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__429"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        3 ตัวโต๊ด
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__433"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        2 ตัวบน
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__437"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        2 ตัวล่าง
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__441"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        วิ่งบน
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        วิ่งล่าง
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="float-right">
                หากไม่ต้องการถือสู้ในประเภทใด ๆ ให้ใส่ 0
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BModal,
    DatePicker,
  },
  data() {
    return {
      show: false,
      value: '',
      StopBetTime: null,
      showoff: false,
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    isShow() {
      this.showoff = !this.showoff
    },
  },
}
</script>

<style>
.settime{
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  border-radius: 6px;
}
</style>
